import {
  AFFILIATION_NAME_OPERATOR,
  getDiscountAmount,
  getPercentageDiscount,
  getProductCategoriesTree,
  hasFreeShipping,
  isBundle,
  isBundleWithoutVariables,
  isConfigurableBundle,
  isMirakl,
  isOperator,
  isVariable,
  priceToNumber,
  toCurrency,
} from '@/lib/utils';
import FallBackIMG from '@/assets/images/fallback-product-card.jpg';
import { woosbDiscountTransformer } from '../product/woosb';
import { badgeTransformer } from './badge';
import { boxTransformer } from './box';

import type { TypeProduct, TypeProductStock } from '@/types';
import type { TypeCardProductGraphQL } from '../../types';
import type { Woosb } from '../product';
import type { WoosbParameters } from '../product/woosb';

type TypeSellerInfoGraphQL = {
  name: string;
  slug: string;
  freeShippingAmount?: string | null;
  alwaysFree?: boolean | null;
} | null;

export type TypeProductCardWordpressGraphQL = {
  status: string;
  databaseId: number;
  name: string;
  sku: string | null;
  type: TypeProduct;
  regularPrice: string;
  salePrice: string | null;
  onSale: boolean | null;
  stockStatus: TypeProductStock;
  price: string;
  uri: string;
  image: {
    sourceUrl: string | null;
    src?: string | null;
    altText: string | null;
  } | null;
  primaryCategory: {
    name: string | null;
    path: string | null;
  } | null;
  productCategoryForSeo:
    | {
        name: string | null;
        slug: string | null;
      }[]
    | null;
  productMarcaForSeo:
    | {
        slug: string | null;
      }[]
    | null;
  sellerInfo: TypeSellerInfoGraphQL;
  caracteriSticasDelProducto: {
    card: TypeCardProductGraphQL | null;
  } | null;
  shippingClasses: {
    edges: {
      node: {
        slug?: string;
      };
    }[];
  } | null;
  // Bundle
  woosbOptionalProducts?: WoosbParameters['woosbOptionalProducts'] | null;
  woosbDiscount?: WoosbParameters['woosbDiscount'] | null;
  woosbDiscountAmount?: WoosbParameters['woosbDiscountAmount'] | null;
  woosbIds?: WoosbParameters['woosbIds'] | null;
  customQuantityDiscounts?:
    | {
        value: number;
      }[]
    | null;
  bundledItems?: {
    type: TypeProduct;
    sku?: string;
    variations: {
      edges: {
        node: {
          databaseId: number;
        };
      }[];
    };
  }[];
  // External
  externalUrl?: string | null;
  // Variable
  metaData?:
    | {
        key: string;
        value: string;
      }[]
    | null;
  variations?: {
    edges:
      | {
          node: {
            sku: string | null;
            databaseId: number | null;
            minQtyOrder: number | null;
            sellerInfo: TypeSellerInfoGraphQL;
          } | null;
        }[]
      | null;
  } | null;
  // Data from Connectif
  isConnectif?: boolean;
  // Cross Selling Customizable Bundle
  defaultWoosb?: {
    slug: string | null;
    image: {
      sourceUrl: string | null;
      altText: string | null;
    } | null;
  } | null;
  woosbMinQty?: number | null;
};

const discountFormatter = (
  discount: Woosb['discount'],
  variantDiscount: { value: number | null }[],
) => {
  const discounts = [
    ...(variantDiscount?.map((i) => (i.value ? i.value : 0)) ?? []),
    discount.type === 'amount'
      ? (discount.amount ?? 0)
      : (discount.percentage ?? 0),
  ];
  const maxDiscount = Math.max(...discounts);
  const allAreEqual = discounts.every((val) => val === maxDiscount);

  if (discount.type === 'percentage')
    return `${allAreEqual ? '' : 'Hasta '}-${maxDiscount}%`;
  if (discount.type === 'amount')
    return `${allAreEqual ? '' : 'Hasta '}-${toCurrency(maxDiscount ?? 0)}`;
};

export const productCardWordpressTransformer = (
  product: TypeProductCardWordpressGraphQL,
) => {
  if (!product) return null;
  if (product.status !== 'publish') return null;

  const card: TypeCardProductGraphQL | null | undefined =
    product?.caracteriSticasDelProducto?.card;

  const IS_FALSE_VARIABLE =
    isVariable(product.type) && product?.variations?.edges?.length === 1;

  const productID = IS_FALSE_VARIABLE
    ? product?.variations?.edges?.at(0)?.node?.databaseId
    : product.databaseId;

  if (!productID) return null;

  const isConfigurable = isConfigurableBundle(
    product.type,
    product.woosbOptionalProducts,
  );

  const productType: TypeProduct = IS_FALSE_VARIABLE
    ? 'SIMPLE'
    : isBundle(product.type) &&
        product?.stockStatus &&
        product?.woosbIds &&
        product?.bundledItems &&
        !isBundleWithoutVariables(product.type, {
          stock: product.stockStatus,
          woosbIds: product.woosbIds,
          bundledItems: product.bundledItems,
        }) &&
        !isConfigurable
      ? 'VARIABLE'
      : product.type;

  const isMiraklProduct = isMirakl(product?.metaData ?? []);

  const sellerInfo = isMiraklProduct
    ? product?.variations?.edges?.at(0)?.node?.sellerInfo
    : product?.sellerInfo;

  const hasBox = card?.box && card?.box?.active;

  const discountBundle = woosbDiscountTransformer({
    woosbDiscount: product.woosbDiscount,
    woosbDiscountAmount: product.woosbDiscountAmount,
  });

  const discount = isConfigurable
    ? discountFormatter(discountBundle, product?.customQuantityDiscounts ?? [])
    : `-${getPercentageDiscount(
        priceToNumber(product.regularPrice),
        priceToNumber(product.salePrice ?? product.price),
      )}%`;

  const regularPrice = toCurrency(
    product.regularPrice?.split('-')?.at(0) ?? '',
  );
  const price = toCurrency(product.price?.split('-')?.at(0) ?? '');
  const salePrice = product.salePrice ? toCurrency(product.salePrice) : null;
  const onSale = Boolean(product.onSale);

  const discountAmount =
    onSale && salePrice
      ? Number.parseFloat(
          getDiscountAmount(
            priceToNumber(regularPrice),
            priceToNumber(salePrice),
          ).toFixed(2),
        )
      : null;

  const minQtyOrder = product?.variations?.edges?.at(0)?.node?.minQtyOrder;

  const hasFreeShippingProduct =
    sellerInfo &&
    hasFreeShipping(product?.shippingClasses?.edges, sellerInfo, product.price);

  const brand = product?.productMarcaForSeo?.at(0)?.slug ?? null;

  const imageSRC =
    card?.image?.sourceUrl ?? product.image?.sourceUrl ?? product.image?.src;

  const imageALT =
    card?.image?.altText ??
    product.image?.altText ??
    card?.name ??
    product.name;

  return {
    name: card?.name || product.name,
    type: productType,
    productID,
    uri: product.uri,
    sku: isMiraklProduct
      ? product.variations?.edges?.at(0)?.node?.sku || null
      : product.sku || null,
    regularPrice,
    price,
    affiliation: isMiraklProduct
      ? (sellerInfo?.name ?? '')
      : AFFILIATION_NAME_OPERATOR,
    ...(!isOperator(sellerInfo?.name ?? '') && {
      sellerInfo: {
        name: sellerInfo?.name ?? '',
        slug: sellerInfo?.slug ?? '',
      },
    }),
    image: {
      src: imageSRC || FallBackIMG.src,
      ...(imageALT && { alt: imageALT }),
    },
    ...(product.stockStatus && { stock: product.stockStatus }),
    ...(product.primaryCategory && {
      primaryCategory: {
        name: product.primaryCategory?.name ?? '',
        href: product.primaryCategory?.path ?? '',
      },
    }),
    ...(discountAmount && { discountAmount }),
    ...(onSale && { onSale, salePrice }),
    ...(IS_FALSE_VARIABLE && minQtyOrder && { minQtyOrder }),
    ...(hasBox && { box: boxTransformer(card.box) }),
    ...(isConfigurable && { isConfigurable }),
    ...(hasFreeShippingProduct && { hasFreeShipping: true }),
    ...(card?.imageBackgroundColor && {
      backgroundColor: card.imageBackgroundColor,
    }),
    ...(card?.badge?.active && {
      badge: {
        ...badgeTransformer(card?.badge, card?.lifeStyle),
        text: card?.badge?.text,
      },
    }),
    ...(product.onSale && {
      discountBadge: {
        ...badgeTransformer(undefined, card?.lifeStyle),
        text: discount,
      },
    }),
    ...(product.productCategoryForSeo && {
      categories: getProductCategoriesTree(product),
    }),
    ...(brand && { brand }),
    ...(product.isConnectif && { isConnectif: true }),
    ...(product.defaultWoosb &&
      product.defaultWoosb.slug &&
      product.defaultWoosb.image?.sourceUrl && {
        woosbData: {
          slug: product.defaultWoosb.slug,
          image: {
            src: product.defaultWoosb.image.sourceUrl,
            ...(product.defaultWoosb.image.altText && {
              alt: product.defaultWoosb.image.altText,
            }),
          },
        },
      }),
    ...(product.woosbMinQty && { woosbMinQty: product.woosbMinQty }),
  };
};
