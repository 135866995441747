import { getPercentageDiscount, getRealURL, priceToNumber } from '@/lib/utils';

import type { productCardWordpressTransformer } from '@/lib/graphql/transformers/card-product/woordpress';
import type { GTMProduct } from './products';

export const normalizeItemServer = (
  data: NonNullable<ReturnType<typeof productCardWordpressTransformer>>,
  name?: string,
  index?: number,
): GTMProduct => {
  const free_item = priceToNumber(data.price) === 0;
  const discountPercentage =
    data.onSale &&
    data.salePrice &&
    getPercentageDiscount(
      priceToNumber(data.regularPrice),
      priceToNumber(data.salePrice),
    );
  const listName = (window?.itemListName || name || 'direct')
    .split(' ')
    .join('_');
  const indexItem = window?.itemIndex ?? index;

  return {
    item_id: data?.sku || data?.name,
    item_id_connectif: data?.productID,
    item_name: data?.name,
    // TODO: cuando se implmente el buy box esto no servirá porque puede haber varios sellers
    affiliation: data?.affiliation,
    index: indexItem,
    item_brand: data?.brand || undefined,
    price: priceToNumber(data.price),
    free_item: free_item ? 'si' : 'no',
    free_item_price: free_item ? priceToNumber(data.regularPrice) : 0,
    original_price: priceToNumber(data.regularPrice),
    discount: data?.discountAmount || undefined,
    discount_percentage: discountPercentage
      ? `${discountPercentage}%`
      : undefined,
    quantity: 1,
    ...data?.categories,
    item_variant: undefined,
    items_pack: undefined,
    item_list_name: listName,
    item_list_id: listName,
    item_custom_box: 'no',
    item_image: data?.image?.src || undefined,
    item_url: getRealURL(data?.uri),
  };
};

export const normalizeItemsServer = ({
  items,
  itemListName,
}: {
  items: NonNullable<ReturnType<typeof productCardWordpressTransformer>>[];
  itemListName?: string;
}) =>
  items.map((item, index) =>
    normalizeItemServer(item, itemListName, index + 1),
  );
