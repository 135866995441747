import { useEffect, useState } from 'react';
import { signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

import { productCardWordpressTransformer } from '@/lib/graphql/transformers';
import { useCart, useCartDispatch } from '@/lib/hooks';
import {
  getPageAffiliation,
  getPageType,
  gtmPush,
  isCartRoute,
  isVariable,
  normalizeItemServer,
  normalizeProducts,
  priceToNumber,
} from '@/lib/utils';
import { getCustomerType, getPageName } from '@/lib/utils/gtm/helpers';

const urlWithoutParams = (url: string | null) => url?.split('?')?.at(0);
const areDistinctUrls = (url1: string | null, url2: string | null) =>
  urlWithoutParams(url1) !== urlWithoutParams(url2);

// TODO: Añadir tipos
export default function Layout({ children, ...props }: any): JSX.Element {
  const [lastView, setLastView] = useState<null | string>(null);
  const router = useRouter();
  const { data, status } = useSession();
  const { getCart } = useCartDispatch();

  useEffect(() => {
    if ((data as any)?.error === 'JWT Expired') signOut();

    if (status === 'authenticated') {
      getCart();
    }
  }, [status, data]);

  const { cart, loading: isCartLoading } = useCart();

  useEffect(() => {
    const getPageView = async (): Promise<void> => {
      const url = router.asPath;

      if (!isCartLoading) {
        const categories: string[] = [];

        cart.contents.products.forEach((item) =>
          item.product.productCategoryForSeo.forEach((category) => {
            if (!categories.includes(category.name)) {
              categories.push(category.name);
            }
          }),
        );

        if (window.oct8ne) {
          window.oct8ne.customData.importeCesta = cart.contentsTotal;
          window.oct8ne.customData.categoriasProductosCesta = categories;
        }
      }

      // check if session && cart are loaded
      if (
        status !== 'loading' &&
        !isCartLoading &&
        areDistinctUrls(lastView, router.asPath)
      ) {
        const isProductPage: boolean = url.includes('/producto/');

        if (window.oct8ne) {
          window.oct8ne.customData.page = url;

          if (!isProductPage) {
            window.oct8ne.customData.productCategory = null;
            window.oct8ne.customData.productPrice = null;
            window.oct8ne.customData.productSeller = null;
          }
        }

        gtmPush({
          event: 'page_view',
          context: {
            pagename: getPageName(),
            user_status: status === 'authenticated' ? 'logged' : 'unlogged',
            page_affiliation: getPageAffiliation(),
            page_type: getPageType(),
            im_customer_type_event: await getCustomerType(),
          },
          user: true,
        });

        // view product page event
        if (isProductPage) {
          if (
            window.oct8ne &&
            !router.pathname.includes('404') &&
            props.breadcrumbs &&
            props.type &&
            props.variations &&
            props.sellerInfo
          ) {
            window.oct8ne.customData.page = url;
            window.oct8ne.customData.productCategory =
              props.breadcrumbs[props.breadcrumbs.length - 2].url;
            window.oct8ne.customData.productPrice = props.price;
            window.oct8ne.customData.productSeller = isVariable(props.type)
              ? props.variations.edges?.at(0).node.sellerInfo?.name
              : props.sellerInfo.name;
          }

          const item = productCardWordpressTransformer(props);

          if (item)
            gtmPush({
              event: 'view_item',
              ecommerce: {
                items: [normalizeItemServer(item)],
                value: priceToNumber(item.price),
                currency: 'EUR',
              },
            });
        }

        // view cart event
        if (isCartRoute(url)) {
          gtmPush({
            event: 'view_cart',
            ecommerce: {
              items: normalizeProducts({ cart, itemListName: 'cart' }),
              value: priceToNumber(cart.total),
              currency: 'EUR',
            },
          });
        }

        setLastView(router.asPath);
      }
    };

    getPageView();
  }, [router.asPath, isCartLoading, status]);

  useEffect(() => {
    getCart();
    document.addEventListener('visibilitychange', (event) => {
      if (!event?.target) return;
      if (document.visibilityState === 'visible') getCart();
    });
    return () => {
      document.removeEventListener('visibilitychange', () => getCart());
    };
  }, []);

  return <>{children}</>;
}
